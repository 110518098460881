<template>
  <div class="dashboard-page dashboard-center">
    <div class="content-l">
      <m-page-title title="Building Information" />
      <div class="select-box">
        <span>Select apartment</span>
        <el-select
          :value="address.value"
          filterable
          size="small"
          @change="addressChange"
        >
          <el-option
            v-for="(item, index) in roomMembers"
            :key="index"
            :value="item"
            class=""
          >
            <span class="select-option-label">
              {{ item.apartmentFloorName }}{{ item.apartmentRoomName }}
            </span>
            <span class="select-option-address">
              {{ item | addressJoinStr }}
            </span>
          </el-option>
        </el-select>
      </div>
      <img
        class="address-img g-radius-4 g-pointer"
        :src="address.photo"
        :onerror="defaultImg"
        alt=""
      />
      <div class="user-box">
        <img src="@/static/icon/dashboard/user.png" alt="" class="user-img" />
        <div class="user-r-box">
          <span class="user-status">
            Your status - ({{ address.apartmentFloorName
            }}{{ address.apartmentRoomName }}) {{ userStatus }}
          </span>
          <!--          todo:需格式化参数-->
          <span class="user-move-in" v-if="address.isMoveIn || roomstu">
            Move In -
            {{
              address.moveInStartDate ? address.moveInStartDate : "Upcoming"
            }}</span
          >
        </div>
      </div>
    </div>
    <div class="content-r g-scroll-style" id="optionsBox">
      <div
        class="fn-items g-hover-pointer"
        v-for="(item, index) in itemsArr"
        :key="index"
        @click="itemClick(item)"
      >
        <img :src="item.img" alt="" class="fn-icon g-radius-50" />
        <span class="fn-label">{{ item.label }}</span>
        <div class="fn-msg g-radius-50 flex-center" v-if="item.msg">
          {{ item.msg }}
        </div>
      </div>
      <div class="fn-items fill" v-if="this.itemsArr.length % 2 !== 0"></div>
      <div class="fn-items fill" v-if="this.itemsArr.length % 2 !== 0"></div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import { addressJoinStr } from "@/utils/tools";

const itemsArr = [
  {
    label: "Building Information",
    img: require("@/static/icon/dashboard/BuildingInformation.png"),
    msg: null,
    page: "/buildingInfo",
    sideBarStatus: -1,
  },

  {
    label: "New Booking",
    img: require("@/static/icon/dashboard/RescheduleMoveIn.png"),
    msg: null,
    page: "/newBooking",
    sideBarStatus: -1,
  },
  {
    label: "Building Contacts",
    img: require("@/static/icon/dashboard/BuildingContactDetails.png"),
    msg: null,
    page: "buildingContacts",
    sideBarStatus: -1,
  },
  {
    label: "My Booking",
    img: require("@/static/icon/dashboard/myBooking.png"),
    msg: null,
    page: "/myBooking",
    sideBarStatus: -1,
  },
  {
    label: "Noticeboard",
    img: require("@/static/icon/dashboard/Noticeboard.png"),
    msg: 0,
    page: "/noticeBoard",
    sideBarStatus: -1,
  },

  {
    label: "Add Additional Apartment",
    img: require("@/static/icon/dashboard/AddBuildingAddress.png"),
    msg: null,
    page: "/buildingAddress",
    sideBarStatus: 4,
  },
  {
    label: "Booking Rules ",
    img: require("@/static/icon/dashboard/MovingGuidelines.png"),
    msg: null,
    page: "/bookingRules",
    sideBarStatus: -1,
  },
  {
    label: "Local Service",
    img: require("@/static/icon/dashboard/LocalService.png"),
    msg: null,
    page: "/LocalService",
    sideBarStatus: -1,
  },
];

export default {
  name: "Guidelines",
  components: {},
  data() {
    return {
      address: {},
      itemsArr,
      roomstu: false,
      roomMembers: [],
      defaultImg: 'this.src="' + require("@/static/image/exhibition.png") + '"',
    };
  },
  computed: {
    ...mapState("apartment", ["room", "apartment"]),
    userStatus() {
      const address = this.address;
      if (!address.apartmentId) return "";
      if (address.type === 0 && address.status === 1) return "Tenant";
      else if (address.type === 0 && address.status === 0) {
        const isMoveIn = address.isMoveIn ? "Residing" : "Non-Residing";
        return `Owner ( ${isMoveIn} )`;
      } else return "Agent";
    },
  },
  filters: {
    addressJoinStr(e) {
      const obj = {
        ...e,
        name: e.apartmentUnitName,
      };
      return addressJoinStr(obj);
    },
  },
  created() {
    this.getRoomMembers();
    // // 清空公寓信息
    // this.setRoom();
    // this.setApartment();
    // 侧边栏状态
    this.navBarShow();
    this.sideBarShow();
    this.setSideBarStatus();
    //把键盘监听事件置空
    document.onkeydown = "";
  },
  methods: {
    ...mapMutations("bar", ["setSideBarStatus", "navBarShow", "sideBarShow"]),
    ...mapMutations("apartment", ["setRoom", "setApartment"]),
    // 点击功能区
    itemClick(e) {
      if (e.page == "/LocalService") {
        this.$notify.warning("Coming Soon");
        return;
      }
      this.setSideBarStatus(e.sideBarStatus);
      e.page && this.$router.push(e.page);
    },
    // 获取建筑列表
    getRoomMembers() {
      this.$axios.get(this.$api.roomMembers).then((res) => {
        if (res.code === "1000") {
          if (res.data.list.length) {
            const arr = [];
            res.data.list.forEach((item) => {
              arr.push({
                ...item,
                value:
                  addressJoinStr({ ...item, name: item.apartmentUnitName }) +
                  "  --  " +
                  item.apartmentFloorName +
                  item.apartmentRoomName,
              });
            });
            this.roomMembers.splice(0, this.roomMembers.length, ...arr);
            // 默认值
            if (!this.room.value) {
              this.addressChange(this.roomMembers[0]);
            } else {
              for (var i = 0; i < this.roomMembers.length; i++) {
                if (
                  this.room.apartmentFloorId ===
                    this.roomMembers[i].apartmentFloorId &&
                  this.room.apartmentId === this.roomMembers[i].apartmentId &&
                  this.room.id === this.roomMembers[i].apartmentRoomId
                ) {
                  this.addressChange(this.roomMembers[i]);
                }
              }
            }
          } else {
            this.$notify.warning(
              "This account has not added any building address."
            );
            this.setSideBarStatus(0);
            this.$router.replace("/contactDetails");
          }
        }
      });
    },
    // 选中
    async addressChange(e) {
      if (!e.isMoveIn) {
        var Roomstu = await this.$axios.get(this.$api.isHaveProcessing, {
          params: { apartmentRoomId: e.apartmentRoomId },
        });
        this.roomstu = Roomstu.data.isHaveProcessing;
      }
      this.address = e;
      this.setRoom({
        apartmentFloorId: e.apartmentFloorId,
        apartmentId: e.apartmentId,
        apartmentUnitId: e.apartmentUnitId,
        id: e.apartmentRoomId,
        name: e.apartmentRoomName,
        value: e.apartmentRoomName,
        floorValue: e.apartmentFloorName,
      });
      this.setApartment({
        city: e.city,
        id: e.apartmentId,
        name: e.apartmentName,
        photo: e.photo,
        province: e.province,
        region: e.region,
        status: e.status,
        street: e.street,
        postcode: e.postcode,
        value: e.apartmentName,
        latitude: e.latitude,
        longitude: e.longitude,
      });
    },
  },
};
</script>

<style scoped lang="scss" src="./scss.scss"></style>
